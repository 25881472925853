import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"

import styled from "@emotion/styled"
import kebabCase from "lodash/kebabCase"

import Layout from "../components/layout"
import SEO from "../components/seo"
import colors from "../components/colors"

const Title = styled.h1`
  margin: 1rem auto;
  text-align: center;
`

const Body = styled.main`
  display: flex;
  flex-flow: row wrap;
`

const CardOutline = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.navy};
  border-radius: 5px;
  color: ${colors.navy};
  background-color: ${colors.lightyellow};
  box-shadow: -3px 3px ${colors.navy};
  width: auto;
  height: 50px;
  align-items: center;
  margin: 1rem;
  &:hover,&:focus {
    color: white;
    background-color: ${colors.pink};
    border-color: white;
  }
`

const Text = styled.h4`
  margin: auto;
  padding: 10px;
  font-family: Messapia Bold;
`

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <SEO title={title} />
    <Title>Tags</Title>
    <Body>
      {group.map(tag => (
        <CardOutline key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            <Text>
              {tag.fieldValue} ({tag.totalCount})
            </Text>
          </Link>
        </CardOutline>
      ))}
    </Body>
  </Layout>
)

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
